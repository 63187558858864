import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from '../templates/blog.module.css'

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/wiki\/Design\/UX-project.*.md$/"}) {
      fileAbsolutePath
      html
    }

    allFile(filter: {absolutePath: {regex: "/wiki\/Design\/UX-project.*.md$/"}}) {
      edges {
        node {
          changeTime
        }
      }
    }
  }
`

export default function UXResearch({data}) {
  const {markdownRemark} = data
  const {html} = markdownRemark
  const changeTime = data.allFile.edges[0].node.changeTime.split('T')[0]
  const title = 'UX Research'
  return (
    <Layout>
      <SEO title={title} />
      <div className={styles.blogContainer}>
        <h1>{title}</h1>
        <em>Last updated on {changeTime}</em>
        <div
          className={styles.blogContent}
          dangerouslySetInnerHTML={{__html: html}}
        />
        <p>
          This file comes from our{' '}
          <a href="https://gitlab.com/staltz/manyverse/-/wikis/UX-project:-%22Welcome-to-the-Manyverse%22">
            Wiki page
          </a>{' '}
          on GitLab.
        </p>
      </div>
    </Layout>
  )
}
